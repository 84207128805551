'use client'

import { FunctionComponent, useContext, useEffect } from 'react'
import { gtmPush, md5Encrypt } from '../../utils/gtmHelpers'
import { ContrastContext } from '../../context/ContrastContext'
import { GTMPageEvent } from '../../../domain/gtm'
import { UserContext } from '../../context/UserContext'
import { getCookie } from 'cookies-next'
import dayjs from 'dayjs'

type GtmProps = {
  type: GTMPageEvent['page_type']
  clientStatut?: string
}
const GtmPushPageView: FunctionComponent<GtmProps> = ({ type }) => {
  const { contrast } = useContext(ContrastContext)
  const { user } = useContext(UserContext)
  const userToken = getCookie('userToken')

  useEffect(() => {
    let event: GTMPageEvent = {
      event: 'page_view',
      page_type: type,
      accessibility: contrast ? 'accessible' : 'standard',
      user_id: '',
      user_status: 'logged_out',
      client_status: '',

      cid_hash: '',
      cfn_hash: '',
      cln_hash: '',
      cp_hash: '',
      cci: '',
      cco: '',
      cpc: '',
      ca: '',
    };
    if (userToken) {
      if (user) {

        event = {
          ...event,
          user_id: md5Encrypt(user.customer_id),
          user_status: 'logged_in',
          client_status: user?.has_orders ? 'ancien client' : 'nouveau client',

          cid_hash: md5Encrypt(user.email),
          cfn_hash: md5Encrypt(user.firstname),
          cln_hash: md5Encrypt(user.lastname),
        };
        if (user.date_of_birth) {
          const dob =  dayjs(user.date_of_birth);
          const age = dayjs().diff(dob, 'year');
          if (Number.isInteger(age) && age > 0 && age < 150) {
            event.ca = age;
          }
        }

        let defaultAddress = user.addresses?.find((a) => a.default_shipping);
        if (!defaultAddress) {
          defaultAddress = user.addresses?.find((a) => a.default_billing);
        }
        if (defaultAddress) {
          event.cci = defaultAddress.city;
          event.cco = defaultAddress.country.code;
          event.cpc = defaultAddress.postcode;
          if (defaultAddress.telephone && defaultAddress.telephone.length > 0) {
            event.cp_hash = md5Encrypt(defaultAddress.telephone);
          }
        }
        gtmPush(event)
      }
    } else {
      gtmPush(event)
    }
  }, [contrast, user])

  return null
}

export default GtmPushPageView
