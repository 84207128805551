'use client'

import { motion } from 'framer-motion'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { SelectionItem } from '../../../domain/selectionItem'
import { useIsomorphicLayoutEffect } from '../../../core/hook/useIsomorphicLayout'
import {
  initArrowPositionForListSelection,
  initSliderForListSelection,
} from '../../utils/ListSelection.utils'
import ListSelectionItem from '../ListSelectionItem/ListSelectionItem'
import { ArrowNavigationLeft, ArrowNavigationRight } from '../SliderUtils/ArrowNavigation'
import { useIsMobile } from '../../utils/screen.utils'
import { handleError } from '../../../core/hook/utils'
import { useQuery } from '../../../core/hook/useQuery'
import { ProductClientDocument } from '../../../queries/ProductClient.gql'
import { useIntersectionObserver } from 'usehooks-ts'
import { gtmPush, gtmCategory } from '../../utils/gtmHelpers'

type ListSelectionProps = {
  items: SelectionItem[]
  name?: ReactElement<'h2' | 'h3' | 'h4' | 'h5' | 'h6'>
  parentClassName?: string
  centerInsufficientSlides?: boolean
  hideColorVariations?: boolean
  disableSwiperOfImages?: boolean
  isHome?: boolean
  listId?: string
  listName?: string
}

const ListSelection = ({
  items,
  name,
  parentClassName,
  centerInsufficientSlides,
  hideColorVariations = false,
  disableSwiperOfImages = false,
  isHome = false,
  listId = '',
  listName = '',
}: ListSelectionProps) => {
  const isMobile = useIsMobile()

  const { data } = useQuery(ProductClientDocument, {
    variables: {
      filters: { url_key: { in: items.map((item) => item.urlKey) } },
      pageSize: items.length,
    },
    fetchPolicy: 'no-cache',
  })
  const [itemsData, setItemsData] = useState<SelectionItem[]>(items)
  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 0,
    freezeOnceVisible: true,
  })
  const swiperElemRef = useRef<HTMLDivElement>(null)
  const itemsRef = useRef(items)
  itemsRef.current = items

  useEffect(() => {
    if (!data?.products?.items) {
      setItemsData(
        itemsData.map((item) => ({
          ...item,
          stockStatus: 'Loading',
        })),
      )
    } else {
      setItemsData(
        itemsData.map((item) => {
          const product = data.products?.items!.find((product) => product!.id === item.id)

          return {
            ...item,
            stockStatus: product?.stock_status ?? item?.stockStatus,
          }
        }),
      )
    }
  }, [data])

  useIsomorphicLayoutEffect(() => {
    if (!isIntersecting) {
      return
    }
    const swiper = initSliderForListSelection(parentClassName, centerInsufficientSlides, {
      init: false,
    })

    initArrowPositionForListSelection()

    function pushViewItemListGtmEvent() {
      const slideList = swiper.el.querySelectorAll<HTMLDivElement>('.swiper-slide-visible')
      const gtmItems: any[] = []
      for (let i = 0; i < slideList.length; i += 1) {
        const slideEl = slideList[i]
        const cardEl = slideEl.querySelector<HTMLDivElement>('.card') as HTMLDivElement
        const itemId = Number.parseInt(cardEl.dataset.productId || '', 10)
        if (!Number.isInteger(itemId)) {
          continue
        }
        const p = itemsRef.current.find((item) => item.id === itemId)
        if (!p) {
          continue
        }
        gtmItems.push({
          item_name: p.gtm_name,
          item_brand: 'Messika',
          price: p.priceRange?.minimum_price.regular_price,
          index: slideEl.dataset.index,
          item_list_id: 'selection_accueil',
          item_list_name: 'Selection Accueil',
          ...gtmCategory(p.gtm_categories),
        })
      }
      if (gtmItems.length > 0) {
        gtmPush({
          event: 'view_item_list',
          ecommerce: {
            item_list_id: 'selection_accueil',
            item_list_name: 'Selection Accueil',
            items: gtmItems,
          },
        })
      }
    }

    swiper.on('init', function () {
      pushViewItemListGtmEvent()
    })

    swiper.on('slideChange', function () {
      pushViewItemListGtmEvent()
    })

    swiper.init()

    return () => {
      try {
        swiper.destroy?.()
      } catch (error) {
        // TODO workaround
        handleError(error, false)
      }
    }
  }, [parentClassName, centerInsufficientSlides, isIntersecting])

  if (itemsData.length < 1) {
    return null
  }

  return (
    <section ref={ref} className='list-selection'>
      {name}
      <motion.div
        style={{ y: 150, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.5 }}
        className='list-selection-wrapper'>
        <div className='swiper list-selection-swiper' ref={swiperElemRef}>
          <div className='swiper-wrapper list-selection-swiper-wrapper'>
            {itemsData.map((item, i) => (
              <div
                className='swiper-slide list-selection-swiper-slide'
                key={item.urlKey}
                data-index={i}>
                <ListSelectionItem
                  item={item}
                  isHome={isHome}
                  listId={listId}
                  listName={listName}
                  className='list-selection'
                  hideColorVariations={hideColorVariations}
                  keepOnlyOneImage={disableSwiperOfImages}
                  position={i + 1}
                />
              </div>
            ))}
          </div>
        </div>

        {(itemsData.length > 4 || (isMobile && itemsData.length > 2)) && (
          <div className='navigation-container'>
            <div className='bloc-navigation bloc-navigation-left'>
              <ArrowNavigationLeft />
            </div>

            <div className='explorer-animation bullet'>
              <div className='explorer-animation-bullet' />
              <div className='explorer-animation-bullet' />
              <div className='explorer-animation-bullet' />
            </div>

            <div className='bloc-navigation bloc-navigation-right'>
              <ArrowNavigationRight />
            </div>
          </div>
        )}
      </motion.div>
    </section>
  )
}

export default ListSelection
