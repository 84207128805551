import { useContext } from 'react'
import { SelectionItem } from '../../../domain/selectionItem'
import { ConfigContext } from '../../context/ConfigContext'
import { PushSmall } from '../Push/PushSmall'
import { sortProductMediaGalleryByRoles } from '../../utils/Product.utils'
import { ROLE_FOCUS, ROLE_SMALL } from '../../utils/media.utils'

type SelectionItemProps = {
  className?: string
  item: SelectionItem
  hideColorVariations?: boolean
  keepOnlyOneImage?: boolean
  position?: number
  isHome?: boolean
  listId?: string
  listName?: string
}

const ListSelectionItem = ({
  item,
  className,
  hideColorVariations = false,
  keepOnlyOneImage = false,
  position,
  isHome = false,
  listId = '',
  listName = '',
}: SelectionItemProps) => {
  // Is ecommerce
  const { isEcommerce } = useContext(ConfigContext)

  const stockStatus = item.stockStatus === 'IN_STOCK' || item.stockStatus === "Loading" && isEcommerce()
  const enableDirectAddToCart = item.typename === 'SimpleProduct' && item.stockStatus === 'IN_STOCK'

  const images =
    (item.mediaGallery &&
      sortProductMediaGalleryByRoles(item.mediaGallery, [ROLE_SMALL, ROLE_FOCUS])) ||
    []

  return (
    <PushSmall
      id={item.id}
      mediaGallery={keepOnlyOneImage && images.length > 0 ? [images[0]] : images}
      name={item.name}
      gtm_name={item.gtm_name}
      isLoading={item.stockStatus === "Loading"}
      title={item.title}
      titleDescription={item.titleDescription}
      priceRange={item.priceRange}
      urlKey={item.urlKey}
      parentClassNameList={className}
      colorVariations={!hideColorVariations ? item.colorVariations : undefined}
      isExcluWeb={item.isExcluWeb}
      isNew={item.isNew}
      isIconique={item.isIconique}
      stockStatusProduct={stockStatus}
      enVente={item.enVente}
      typename={item.typename}
      customPrice={item.customPrice}
      mariage={item.mariage}
      directAddToCart={enableDirectAddToCart}
      gtm_categories={item?.gtm_categories ?? item.categoriesOfProduct?.label}
      couleurMetalExtended={item.couleurMetalExtended}
      position={position}
      sku={item.sku}
      isHome={isHome}
      listId={listId}
      listName={listName}
    />
  )
}

export default ListSelectionItem
